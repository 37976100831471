<template>
    <div class="md_main">
        <div class="md_top">
            <div class="md_top_son">
                <el-breadcrumb separator="/" style="margin-top: 12px">
                    <el-breadcrumb-item ><router-link :to="{path:'/'}">首页</router-link></el-breadcrumb-item>
                    <el-breadcrumb-item>修改资料</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="mdm_son">
            <el-form  label-width="100px" style="width: 400px;margin: 0px auto;padding-top: 30px">

               <!-- <el-form-item label="手机号码" >
                    <el-input v-model="formdata.mobile" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" >
                    <el-input v-model="formdata.mail" placeholder="请输入邮箱"></el-input>
                </el-form-item>-->
                <el-form-item label="旧密码" >
                    <el-input type="" v-model="formdata.oldPassword" show-password placeholder="请输入旧密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" >
                    <el-input v-model="formdata.newPassword" show-password placeholder="请输入新密码"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" >
                    <el-input v-model="sureNewPassword" show-password placeholder="请输入新密码"></el-input>
                </el-form-item>
                <div style="text-align: center">
                    <el-button type="primary" @click="changeFin" :loading="btnLoading" style="text-align: center">确认修改</el-button>
                </div>

            </el-form>
        </div>

    </div>
</template>

<script>
    import {
        passChange,
    } from "@/Api/finance/home";
    import { encrypt,decrypt } from "@/Util/auth";
    import { removeToken } from "@/Util/auth";
    export default {
        name: "index",
        data() {
            return {
                sureNewPassword:'',
                btnLoading:false,
                formdata:{
                    "name": localStorage.getItem('username'), //账号名称
                    "mobile": "", //手机号码
                    "mail": "", //邮箱
                    "oldPassword": "", //旧密码
                    "newPassword": "" //新密码
                }
            }
        },
        methods: {
            changeFin(){
                if(this.formdata.newPassword==this.sureNewPassword){
                    this.btnLoading=true
                    let obj = this.formdata
                    let ss= encrypt(JSON.stringify(obj))
                    passChange(ss).then(res => {
                        let dd= JSON.parse(decrypt(res))
                        if (dd.code == 200) {
                            this.$message.success(`修改成功`);
                            this.btnLoading=false
                            removeToken();
                            this.$router.push({
                                name: "login"
                            });

                        }else {
                            this.btnLoading=false
                            alert(dd.msg);
                        }

                    })
                }else {
                    this.$message.error(`两次密码输入不一致`);
                }

            }
        }
    }
</script>

<style lang="less" scoped>
    .md_main{
        background: white;
        height: 100%;
        display: flow-root;
        .md_top{
            width: 100%;
            background: #EEEEEE;
            height: 42px;
            border: 1px solid #E5E5E5;
            .md_top_son{
                width: 1200px;
                margin: 0px auto;
                font-size: 16px;
                color: #999999;
                line-height: 42px;
                span{
                    margin-right: 5px;
                }
            }
        }
        .mdm_son{
            width: 1200px;
            margin: 0px auto;
            min-height: 487px;
        }
    }
</style>
